import {useEffect, Suspense, lazy} from 'react'
import { Loader } from 'semantic-ui-react'
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import './App.css';

const Meet = lazy(() => import('./pages/meet/index'))
const LandingPage = lazy(() => import('./pages/meet/landingpage'))
const CreateForm = lazy(() => import('./pages/meet/create'))
const VCList = lazy(() => import('./pages/meet/list'))

const Notification = lazy(() => import('./pages/common/notification'))
const Profile = lazy(() => import('./pages/common/profile'))
const PageNotFound = lazy(() => import('./pages/common/notfound'))

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  return (
      <Router>
        <Suspense fallback={<Loader active inline style={{margin: 10}}/>}>
          <ScrollToTop />
          <Routes>
             <Route exact path='/:app_id/:room_slug' element={<Meet />}></Route>
             <Route exact path='/create-vc' element={<CreateForm />}></Route>
             <Route exact path='/vc-list' element={<VCList />}></Route>
             <Route exact path='/' element={<LandingPage />}></Route>
             <Route exact path='/notification' element={<Notification/>}></Route>
             <Route exact path='/profile' element={<Profile switch_profile_allowed={true} show_external_link={true}/>}></Route>
             <Route exact path='*' element={<PageNotFound/>}></Route>
          </Routes>
        </Suspense>
      </Router>
  );
}

export default App;
